<template>
  <router-view />
</template>

<style lang="scss">
@font-face {
  font-family: 'Noto Sans SC';
  src: url("/public/fonts/NotoSansSC-VariableFont_wght.ttf") format('truetype');
}

#app {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-family: 'Noto Sans SC';
}
</style>
