import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './styles/common.scss'
// 引入element
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import locale from '../node_modules/element-plus/lib/locale/lang/zh-cn'
// 自定义组件

const app = createApp(App)
app.use(router)
app.use(ElementPlus, { locale })
app.mount('#app')

