import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "MainPage",
    redirect: "/",
    component: () => import("@/views/MainPage/index"),
    children: [
      {
        path: "/",
        name: "",
        component: () => import("@/views/Home/index"),
      },
      {
        path: '/DigitalTwins',
        name: 'DigitalTwins',
        component: () => import('@/views/DigitalTwins/index')
      },
      {
        path: '/ProductionControl',
        name: 'ProductionControl',
        component: () => import('@/views/ProductionControl/index')
      },
      {
        path: '/SubstationMaintenance',
        name: 'SubstationMaintenance',
        component: () => import('@/views/SubstationMaintenance/index')
      },
      {
        path: '/BusinessContact',
        name: 'BusinessContact',
        component: () => import('@/views/BusinessContact/index')
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
